import emailjs from "@emailjs/browser";

const contact_form = document.querySelector("#contact_form");
const contact_method = document.querySelectorAll('.dropdown_items ul li')
const info_text = document.querySelector('#info_text')
const phone_number = document.querySelector('.phone_number')
const contact_overlay = document.querySelector('.contact_overlay')
const finish_button = document.querySelector('#finish_button')
const contact_close_button = document.querySelector('.contact_close_button')


contact_method.forEach((items,index)=>{
    items.onclick=()=>{
       if(index == 0){
        contact_form.setAttribute('data-id',items.textContent)
        info_text.innerHTML  = items.textContent  

        Object.assign(phone_number.style, {
            height: '0',
            overflow: 'hidden',
            display:'none'
        })

       }else if(index == 1){
        contact_form.setAttribute('data-id',items.textContent)
        info_text.innerHTML  = items.textContent

        Object.assign(phone_number.style, {
            height: 'max-content',
            overflow: 'auto',
            display:'flex'
        })
       }
    }
})


const template_id = "template_kpmdfmh";
const service_id = 'service_x8lxa4u'
const contact_submit = document.querySelector('.contact_submit')

emailjs.init({
  publicKey: "dt-Dt15DPel58pMXY",
});

contact_form.addEventListener("submit", function (e) {

const name_value = document.querySelector('.name_space').value
const email_value = document.querySelector('.email_text').value
const messages = document.querySelector('.messages').value
const phone_number_input = document.querySelector('.phone_number_input').value
const code_holder = document.querySelector('.code_holder').innerText


  e.preventDefault();

  console.log(name_value, email_value)

  function contactMethod(){
    if(contact_form.getAttribute('data-id') == "Email"){
             return `Email: ${email_value}`
        }else if (contact_form.getAttribute('data-id') == "Phone"){
            return `Phone: ${code_holder} ${phone_number_input}`
        }
  }

  const templateParams = {
    from_name: name_value,
    message: messages,
    contact_method: contactMethod(),
    email: email_value,
  };

  console.log(templateParams.contact_method)

  emailjs.send(service_id, template_id, templateParams).then(
    (response) => {
      console.log("SUCCESS!", response.status, response.text);
      contact_submit.style.display = 'flex'
    },
    (error) => {
      console.log("FAILED...", error);
    }
  );


Object.assign(contact_overlay.style,{
    visibility:'visible',
    opacity:1
})

});



finish_button.onclick=()=>{
    const name_value = document.querySelector('.name_space')
const email_value = document.querySelector('.email_text')
const messages = document.querySelector('.messages')
const phone_number_input = document.querySelector('.phone_number_input')

    Object.assign(contact_overlay.style,{
        visibility:'hidden',
        opacity:0
    })

    name_value.value=""
    messages.value=''
    email_value.value=''
    phone_number_input.value=''

    contact_submit.style.display = 'none'
}

contact_close_button.onclick=()=>{
    Object.assign(contact_overlay.style,{
        visibility:'hidden',
        opacity:0
    })
}

